@keyframes zoomInOut {
    0% {
      transform: scale(1); /* Initial scale value */
    }
    50% {
      transform: scale(1.1); /* Zoom-in scale value */
    }
    100% {
      transform: scale(1); /* Original scale value */
    }
  }
  
  .zoom-effect {
    animation: zoomInOut 2s infinite; /* Animation duration and repetition */
  }

  .filepond--drop-label {
    color: #4c4e53;
  }
  
  .filepond--label-action {
    text-decoration-color: #babdc0;
  }
  
  .filepond--panel-root {
    border-radius: 2em;
    background-color: #edf0f4;
    height: 1em;
  }
  
  .filepond--item-panel {
    background-color: #595e68;
  }
  
  .filepond--drip-blob {
    background-color: #7f8a9a;
  }
  